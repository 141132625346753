<template>
  <form action="#" @submit.prevent="submitRecord" class="record_form">
    <div class="fields_wrapper">
      <custom-select-2
          :label="'Эмоция'"
          :options="emotionList"
          v-model="recordForm.emotion_id"
          name="emotion_list"
      />
      <div class="intensity_wrapper">
        <custom-select-2
            v-if="recordForm.emotion_id"
            :label="'Интенсивность'"
            :options="intensity"
            :labelWidth="80"
            v-model="recordForm.intensity"
            name="intensity"
        />
      </div>
      <custom-select-2
          v-if="recordForm.intensity"
          :label="'Причина'"
          :options="triggerList"
          v-model="recordForm.trigger_id"
          name="trigger_list"
      />
    </div>
    <div v-if="recordForm.emotion_id && recordForm.trigger_id && recordForm.intensity" class="button_wrapper">
      <button :disabled="disabled" class="btn btn-accent">
        Сохранить
      </button>
    </div>
  </form>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "@vue/reactivity";
import {onBeforeMount, reactive, ref} from "@vue/runtime-core";
import {notify} from "@kyvg/vue3-notification";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";

export default {
  name: "DiaryRecordForm",
  components: {CustomSelect2},
  props: {
    availableInterval: {
      type: String,
    }
  },
  setup(props, context) {
    const store = useStore();
    const disabled = ref(false);
    const emotionList = computed(() =>
        store.state.diary.emotionList.map((el) => {
          return {
            id: el.id,
            text: el.value,
          }
        }));
    const triggerList = computed(() =>
        store.state.diary.triggerList.map((el) => {
          return {
            id: el.id,
            text: el.value,
          }
        }));

    const intensity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const createdRecord = computed(() => store.state.diary.createdRecord);

    const today = new Date();
    const date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    const current_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + date;

    const recordForm = reactive({
      emotion_id: null,
      intensity: null,
      trigger_id: null
    });

    onBeforeMount(() => {
      store.dispatch("diary/getEmotionList");
      store.dispatch("diary/getTriggerList");
    });

    const submitRecord = () => {

      if (!recordForm.emotion_id || !recordForm.intensity || !recordForm.trigger_id) {
        notify({
          text: 'Пожалуйста, заполните все поля',
          type: "warning",
        })
      } else {
        disabled.value = true;
        store.dispatch("diary/createRecord", {
          emotion_id: recordForm.emotion_id,
          intensity: recordForm.intensity,
          trigger_id: recordForm.trigger_id,
          record_interval: props.availableInterval,
          record_date: current_date,
        }).then(() => {
          if (createdRecord.value && createdRecord.value.emotion) {
            const message = 'Вы испытывали эмоцию ' + createdRecord.value.emotion + ' с интенсивностью '
                + createdRecord.value.intensity + ' в дату ' + createdRecord.value.date + ' в ' + createdRecord.value.recorded_at;
            notify({
              text: message,
              type: "success",
            });
            store.dispatch('diary/getRecords');
            disabled.value = false;
            context.emit('recordCreated', createdRecord.value.id);
          }
        }).catch(() => {
          disabled.value = false;
        });
      }
    }

    return {
      emotionList,
      triggerList,
      intensity,
      recordForm,
      submitRecord,
      disabled
    }
  }
}
</script>

<style scoped lang="scss">
.fields_wrapper {
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.button_wrapper {
  align-self: flex-end;
  padding-bottom: 10px;
}

.record_form {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

@media (max-width: 930px) {
  .fields_wrapper {
    width: 300px;
  }
}

@media (max-width: 450px) {
  .fields_wrapper {
    width: 300px;
  }
}
</style>